export const cookieService = {
  getCookies,
  setCookie,
  hasCookie,
  getCookie,
};

function getCookies() {
  const cookies = {};
  const str = document.cookie.split(";");
  str.forEach((cookie) => {
    const keyValuePair = cookie.trim().split("=");
    cookies[keyValuePair[0]] = keyValuePair[1];
  })

  return cookies;
}

function hasCookie(key) {
  const cookies = getCookies()
  return cookies[key] !== undefined || cookies[key] !== null;
}

function getCookie(key) {
  return getCookies()[key];
}

function setCookie(key, value, days) {
  var date = new Date();
  days = days || 365;
  date.setTime(+ date + (days * 86400000)); //24 * 60 * 60 * 1000
  document.cookie = key + "=" + value + "; expires=" + date.toGMTString() + "; path=/";
}
