import { apiService, jwtService } from "@/services";


export const userService = {
  login,
  logout,
  register: (user) => apiService.POST(`/users`, user),
  getAll: () => apiService.GET(`/users`),
  get: (user_id) => apiService.GET(`/users/${user_id}`),
  update: (user) => apiService.PUT(`/users/${user.id}`, user),
  updateEmail: (user_id, data) => apiService.PUT(`/users/email/${user_id}`, data),
  updatePassword: (user_id, data) => apiService.PUT(`/users/password/${user_id}`, data),
  resetPassword: (email) => apiService.POST(`/sendmail/passwordforgot`, email),
  delete: (user_id) => apiService.DELETE(`/users/${user_id}`),
  requestDelete: (user_id) => apiService.GET(`/company/deleterequest/${user_id}`),
};

async function login(username, password) {
  /*  return apiService.POST("/login", { email: username, password }).then(async (data) => {
     if (data.access_token) {
       const set = await jwtService.set(data.access_token);
 
       if (!set) return Promise.reject("JWT Token isn't valid");
     }
     return data;
   }) */
  const response = await apiService.POST("/login", { email: username, password });
  const set = await jwtService.set(response.access_token);
  if (!set) throw new Error("JWT Token isn't valid");
  return response;
}

async function logout() {
  jwtService.remove();
  await apiService.GET("/logout");
}
